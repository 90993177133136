/* global console AuthenticationContext location setTimeout*/
// app.js and vendor.js
import "event-source-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ReactClass from "create-react-class";
import {CSSTransitionGroup} from "react-transition-group";

import Config from "./config";
import Router from "./components/router";
import routes from "./routes";
// import {Header, Sidebar, Content} from "./components/layout";

import {
  DialogContainer,
  Notifications,
  LoadingIndicator
} from "./components/widgets";
import {AuthService} from "./modules/auth";
import Service from "./Service";
import ApiClient from "./components/api-client";
import AppBar from "./modules/common/AppBar.jsx";
import NavBar from "./modules/common/NavBar.jsx";
import UtcClock from "./modules/common/ClockComponent.jsx";
import NavList from "./navigation-list";
import AI from "./appInsights";
import * as msal from "@azure/msal-browser";
import Util from "./components/util";
import AnswerAdviser from "./modules/answer-adviser";
import util from "./components/util";
import UserPermissions from "./modules/auth/UserPermissions";
import ScAuthService from "./modules/auth/ScAuthService";
import AdminService from "./modules/admin/Service";

const DEFAULT_ROUTE = '/appliance-search'; // '/appliance-search

const EmptyView = props => {
  return (<div className="view"></div>);
};

const isPromise = type => {
  return typeof type.then === "function";
};

const Api = ApiClient.instance,
    AnonApi = ApiClient.anonInstance,
    convertCatProjectIntoNav = navList => {
      return navList.map((navItem, i) => {
        return {
          label: navItem,
          target: "/project/" + navItem,
          activeWhen: [new RegExp("/^/project/"+navItem+"/?[^/]+/")]
        };
      });
    };

const App = ReactClass({
  displayName: "App",
  getInitialState() {
    return {
      dialog: null,
      isLoadingView: false,
      requiresAuth: false,
      expand: false,
      sidebar: false,
      isLeftNavPinned: false,
      navList: NavList,
      busy: false,
      outageEnabled: false,
      outageMessage: null,
      content: {
        component: EmptyView,
        context: {
          route: {
            path: ""
          }
        }
      }
    };
  },

   initialize() {
    const msall = AuthService.getAuthContextObject();
    const callbackId = msall.addEventCallback(this.handleMsalEventCallback);
    AuthService.setMsalEventCallbackId(callbackId);
    msall.handleRedirectPromise()
    .then((tokenResponse)=>{
      let accountObj;
      if(tokenResponse && tokenResponse.account){
          accountObj = tokenResponse.account;
          msall.setActiveAccount(accountObj);
      }
      else{
        const accounts = msall.getAllAccounts();
        if(accounts.length === 0){
          this.handleRouteOnValidToken(null, "/", null);
            return;
        }
        else{
          accountObj = accounts[0];
        }
      }
      AuthService.getAPIToken(false,accountObj)
      .then((authResult)=>{
        this.appContext.showLoading(false);
        const apiToken = authResult.accessToken;
        console.log("API token acquired!");
        if(!util.isLoggedInUsingSC()){
            this.setAuthInfo(apiToken);
        }
        // Service.recordLogin(apiToken).then(_ => {
        //   this.fetchCatProjects();
        // });
      })
      .catch((e)=>{
        this.appContext.showLoading(false);
        this.setState({
          requiresAuth: true
        });
        let hashh = window.location.hash.trim();
        if(!(hashh === "/" || hashh === "#/" || hashh === "")) {    
          sessionStorage.setItem("url",hashh.substring(2, hashh.length));
        }
        this.handleAuthErrors(e);
      })
    })
    .catch((e)=>{
      this.handleAuthErrors(e);
      this.appContext.showLoading(false);
    }) ;
  },

  handleMsalEventCallback(message){
     //Login/logout errors handled in resepective promise resolution
     //If we need specific checks when login start or ended etc. cases,
     //those events will be passed here to handle further.
     console.log('MSAL Callback:',message);
     switch(message.eventType){
       case msal.EventType.LOGOUT_SUCCESS:
          const accessToken = AuthService.getAccessToken(),
          jwtToken = Util.parseAccessToken(accessToken),
          userSessoinId = Util.getUserSessionId(),//sessionStorage.getItem("userSessoinId");
          timeStamp = new Date().getTime();
          AI.trackUserLoginEvent("USER_LOGOUT",{email: jwtToken.email, userName: jwtToken.name , 
              groups: JSON.stringify(jwtToken.groups),
              userSessoinId, timeStamp});
          console.log("Logout returned");
          //sessionStorage.removeItem('userSessoinId');
          Util.removeUserSessionId();
          sessionStorage.removeItem('applianceSessionID');
            Config.auth = {
              token: null
            };
            AuthService.setAccessToken(null),
            this.handleRouteOnValidToken(null, "/", null);
          break;
          
        case msal.EventType.LOGOUT_FAILURE:
          this.appContext.notifications.warn("Logout failed");
          break;
     }
  },

  handleAuthErrors(e){
    console.log("Error:", e);
    if(e.errorMessage.includes('NeedSCLogin')){
      return;
    }
    if(e instanceof msal.BrowserAuthError){
      this.appContext.notifications.error(e.errorMessage);
    } else if(e instanceof msal.AuthError){
      this.appContext.notifications.error(e.errorMessage);
    } else{
      this.appContext.notifications.error( "An Unknown error occured. Try again later or contact your administrator if error persists.");
    }
  },
  fetchCatProjects() {
    this.setState({
      busy: true
    });
    Service.getCatProjectList().then(res => {
      if(res && res.length > 0) {
        const nav = convertCatProjectIntoNav(res);   
        let catProjects = {};
        if( UserPermissions.allowViewCatProjects() ){
          catProjects = {
            icon: "icon-folder", label: "Cat Projects",
            activeWhen: [/^\/project\/?[^\/]+/],
            subItems: nav
          };
        }
        this.setState({
          navList: [...NavList, catProjects]
        });
        this.setState({
          busy: false
        });
      }
    }).catch(errRes => {
      this.setState({
        busy: false
      });
      errRes.json().then(err => {
        console.log(err);
        // this.appContext.notifications.error(err.message || "Error in fetching Cat Project list");
      });
    });
  },
  setAuthInfo(token) {
    Config.auth = {
      token: token
    };
    AuthService.setAuth({token});
    this.setState({
      requiresAuth: false
    });
    // let url = sessionStorage.getItem("url");
    //   if(url) {
    //     sessionStorage.removeItem("url");
    //     this.appContext.route(url);
    //   }else {
    //     this.appContext.route(DEFAULT_ROUTE);
    //   }
  },
  handleRouteOnValidToken(token, path, action) {
    this.setState({
      requiresAuth: (token ? false: true)
    });
    this.handleRoute(path, action);
  },
  componentWillMount() {
    this.initialize();
    this.router = Router.create(routes);
    this.router.start((path, action, location) => {
      const isRefFromSc = location.search.includes('ref=sc');
      console.log("REF",window.location);
      //attempt login using SC flow
      if(isRefFromSc){
        ScAuthService.login();
        Util.setSCRedirectUrl(window.location.href.split('?')[0].split('#')[1]);
        return;
      }
      else{
        const activeAccount = AuthService.getAuthContextObject().getActiveAccount();
        const promiseToken = AuthService.getAPIToken(false,activeAccount);
        promiseToken.then(authRes => {
          // console.log("Latest token:",authRes.accessToken);
          if(path == "/")
            path = DEFAULT_ROUTE;
          if(!Config.ConfigData){
            Service.fetchApplianceTypeAndFamilyConfig()
            .then(resJson=>{
              Config['ConfigData'] = resJson.ConfigData;
              this.fetchCatProjects();
            }).catch(e=>{
              console.error("Unable to fetch appliance type and family configs");
            });
          }
          if(this.getAuthToken()){
            this.checkOutageStatus();
          }
            this.handleRouteOnValidToken(authRes.accessToken, path, action);
        }).catch(err => {
          this.handleRouteOnValidToken(null, "/", null);
        });
      }
    });
  },
  componentDidMount() {
    const {requiresAuth} = this.state,
        hash = window.location.hash || "#/";
    this.appContext = this.createContext();
    this.setupApiClient();
    if(window.location.href.includes('token')){
      util.setLoggedInUsingSc(true);
      let mUrl = window.location.href.replace("/#/",'');
      let url = new URL(mUrl);
      //attempt login using SC flow
      // if(isRefFromSc){
      //   util.setLoggedInUsingSc(true);
      //   AuthService.login(location.href);
      //   return;
      // } 
      if(url.search){
        let token = url.searchParams.get('token');
        if(token){
          console.log('Redirect from SC, token received');  
          Config.auth.token = token;
          fetch(Config.scLoginUrl+'/Login/CAPToken',{
            method:'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body:JSON.stringify({
              key:Config.scKey,
              token:token,
            })
          })
          .then((res)=>res.json())
          .then((data)=>{
            /**
             * data {"username":"bruviti",
             * "email":"bruviti.bruviti@subzero.com",
             * "roles":["account:A6UJ9A00V4LT","type:Servicer"]
             * }
             */
                Util.setLoggedInUsingSc(true);
                AuthService.setAuth({
                  ...data,
                  token:token
                })
                const userSessoinId = Util.uniqueSessionId(),
                timeStamp = new Date().getTime();
                sessionStorage.setItem("userSessoinId", userSessoinId);
                AI.trackUserLoginEvent("USER_LOGIN", {email: data.email, userName: data.username , 
                    groups: JSON.stringify([]),
                    userSessoinId, timeStamp});
                AuthService.setAccessToken(token);
                Service.recordLogin(token).then(_ => {
                  if(!Config.ConfigData){
                    Service.fetchApplianceTypeAndFamilyConfig()
                    .then(resJson=>{
                      Config['ConfigData'] = resJson.ConfigData;
                      this.fetchCatProjects();
                    }).catch(e=>{
                      console.error("Unable to fetch appliance type and family configs");
                    });
                  }
                  if(Util.getSCRedirectUrl()){
                    this.handleRouteOnValidToken(token, Util.getSCRedirectUrl(), null);
                    //clear redirect status as this redirect needs to be executed only
                    //once
                    Util.setSCRedirectUrl(null);
                  }
                  else{
                    this.loadHomePage();
                  }
                });
              })
        }
        else{
          console.log('No redirect from SC');  
          Util.setSCRedirectUrl(null);
        }
      }
    }
    else{
      this.loadHomePage();
    }
  },

  updateConfigUrls(){
    if(util.isLoggedInUsingSC()){
      Config.resourcesLink.aaLink = Config.resourcesLink.aaLink.replace('/corp','/partners'); 
      Config.resourcesLink.helpLink = Config.resourcesLink.helpLink.replace('/corp','/partners');
      Config.answerAdvisorLink = Config.answerAdvisorLink.replace('/corp','/partners');
    }
  },

  loadHomePage(){
    this.updateConfigUrls();
    const {requiresAuth} = this.state,
    hash = window.location.hash || "#/";
    if(!requiresAuth || Config.auth.token) {
      this.fetchCatProjects();
      this.checkOutageStatus();
      this.setupOutagePolling();
    }
    if(!hash) {
      window.location.hash = "#/";
    }else {
      // const path = requiresAuth ? hash.substring(1) || "/" : DEFAULT_ROUTE;
      var path = requiresAuth ? "/" :
          (hash === "#/" ? DEFAULT_ROUTE : hash.substring(1));
      if(path.includes('/?token=') && path.includes('returnUrl=')){
        path = DEFAULT_ROUTE;
      }
      // this.handleRoute(path, "INIT");
      // const path = requiresAuth ? "/" : DEFAULT_ROUTE;
      this.appContext.route(path);
    }
  },

  componentWillUnmount() {
    this.router.stop();
    //Remove MSAL event callack
    const msal = AuthService.getAuthContextObject();
    if(msal && AuthService.getMsalEventCallbackId()){
      msal.removeEventCallback(AuthService.getMsalEventCallbackId())
    }
    if (this.outageInterval) {
      clearInterval(this.outageInterval);
    }
  },

  createContext() {
    return {
      notifications: this.notifications,
      showDialog: (Component, props) => {
        this.showDialog(Component, props);
      },
      route: path => {
        this.router.route(path);
      },
      showLoading: (loading = true) => this.indicateLoding(loading),
      getLoadingState: () => this.state.isLoadingView,
      leftNavtoggle: this.toggle,
      fetchCatProjects: this.fetchCatProjects,
      updateOutageStatus: this.checkOutageStatus
    };
  },
  toggle(override, skipPin) {
    if (!skipPin && this.state.isLeftNavPinned) return;
    this.setState({
      expand: typeof(override) === "undefined" ? !this.state.expand : override,
      isLeftNavPinned: (skipPin) ? false : this.state.isLeftNavPinned
    });
  },
  /* close() {
    this.setState({expand: false});
  }, */
  toggleSidebar() {
    this.setState({
      expand: !this.state.expand,
      isLeftNavPinned: false
    });
  },
  pinLeftNav() {
    this.setState({
      isLeftNavPinned: !this.state.isLeftNavPinned,
      expand: !this.state.isLeftNavPinned
    });
  },
  render() {
    const {content: {component: Component, context}, isLoadingView, requiresAuth, outageEnabled, outageMessage} = this.state,
        {expand: isLeftNavBarExpanded, isLeftNavPinned}= this.state;
    return (
      <div className={`app ${isLeftNavBarExpanded ? "leftNav-open" : ""} ${isLeftNavPinned ? "leftNav-pinned" : ""}`}>
        {outageEnabled && outageMessage && (
          <div className="outage-banner">
            <i className="icon icon-alert-triangle" />
            <span>{outageMessage}</span>
          </div>
        )}
        <AppBar application={this.appContext} expand={this.state.expand} toggleSidebar={this.toggleSidebar}
            requiresAuth={requiresAuth} />
        { ( !requiresAuth && this.state.navList
          ? <NavBar application={this.appContext} route={context.route}
              toggle={this.toggle} expand={this.state.expand}
              sidebar={this.state.sidebar} pinLeftNav={this.pinLeftNav}
              navList={this.state.navList}
              busy={this.state.busy} />
          : null)}
        <Notifications ref={comp => this.notifications = comp} />
        <DialogContainer ref="appDialog">
          {this.state.dialog}
        </DialogContainer>
        <div className="view-container">
          <CSSTransitionGroup transitionName="view"
              transitionEnterTimeout={300}
              transitionLeaveTimeout={100}>
            <Component key={context.route.path}
                application={this.appContext} {...context} isLeftNavExpanded={isLeftNavBarExpanded}/>
          </CSSTransitionGroup>
        </div>
        {isLoadingView ? <LoadingIndicator /> : ""}
        {!requiresAuth ? <UtcClock /> : ""}
      </div>
    );
  },

  hideDialog(callback) {
    const {appDialog} = this.refs;
    appDialog.hide(() => {
      this.setState({dialog: null});
      if(callback) callback();
    });
  },

  showDialog(DialogComponent, props) {
    const self = this,
        dialogContainer = this.refs.appDialog,
        dialog = {
          close: val => {
            this.hideDialog(() => {
              if(props.onClose) {
                props.onClose(val);
              }
            });
          }
        },
        dialogContent = (<DialogComponent dialog={dialog} {...props} />);

    // console.log(dialogContent);
    this.setState({
      dialog: dialogContent
    });
    dialogContainer.show();
    return dialog;
  },

  indicateLoding(flag = true) {
    this.setState({
      isLoadingView: flag
    });
  },

  handleRoute(path, action) {
    const userSessoinId =  Util.getUserSessionId(),//sessionStorage.getItem("userSessoinId"),
        applianceSessionID = sessionStorage.getItem("applianceSessionID");
    let param = AnswerAdviser.getCRMTicket();
    const {CRM_TICKET_ID} = param;
    if(this.state.dialog) {
      this.hideDialog();
    }
    const resPromise = this.router.resolve(path, {});
    // console.log(path, action, routeInfo, location);
    this.indicateLoding();
    resPromise && resPromise.then(
      ret => { // ret contains value returned by controller
        AI.trackRoutes(path, {"Component Name": ret.component.displayName, name: "PAGE_VISIT",
            userSessoinId,
            applianceSessionID: applianceSessionID ? applianceSessionID : "", 
            CRM_TICKET_ID: CRM_TICKET_ID ? `${CRM_TICKET_ID}` : "",
            deviceId: ret.context.route.params.selectedDeviceId || ""
          });
        this.setState({
          content: ret
        });
        this.indicateLoding(false);
      },
      rErr => {
        this.router.resolve("/route-error").then(ret => {
          console.log(ret);
          this.indicateLoding(false);
          // this.refs.notifications.error(`Error routing to ${path}: ${rErr}`);
          this.setState({
            content: {
              component: ret.component,
              context: Object.assign({}, ret.context, {
                error: {
                  status: path
                },
                message: "This resource does not exist",
                navbar: false
              })
            }
          });
        });
        // throw new Error(`Error routing to ${path}: ${rErr}`);
      }
    );
  },

  getAuthToken() {
    const user = AuthService.getAuth();
    return user ? user.token : Config.auth.token;
  },
  
  setupApiClient() {
    Api.interceptor(context => {
      // console.log(opts);
      const {options, request: {headers}, response} = context;

      if(!response) {
        // we are sending a request
        const useToken = (typeof options.useToken === "undefined") ? true : options.useToken;
        if(useToken) {
          const promiseToken = AuthService.getAPIToken(false);
          return promiseToken.then(authRes => {
            if(authRes) headers.set("Authorization", "Bearer " + (options.token || authRes.accessToken));
          }).catch(err => {
            //Attempt force refresh token (token might not be cached or removed)
            const promiseToken = AuthService.getAPIToken(true);
            return promiseToken.then(authRes=>{
              const apiToken = authResult.accessToken;
              this.setAuthInfo(apiToken);
              if(authRes) headers.set("Authorization", "Bearer " + (options.token || apiToken));
            }).catch(err=>{
              console.log("An error occured when fetching token from cache. Need login.", err);
              this.appContext.route("/");
            })
          });
        }
      }else {
        return response.then(res => {
          // console.log(response);
          if(res.status === 401) {
            Config.auth = {
              token: null
            };
            AuthService.setAccessToken(null);
            this.setState({
              requiresAuth: true
            });
            this.handleRouteOnValidToken(null, "/", null);
            // this.appContext.notifications.warn("We'll need to log you in first");
          }else if(res.status >= 400 && res.status <= 500) {
            // console.log("All ok");
            return Promise.reject(res);
          }else {
            return Promise.resolve(res);
          }
        });
      }
    });
  },

  setupOutagePolling() {
    // Check every 5 minutes
    this.outageInterval = setInterval(() => {
      this.checkOutageStatus();
    }, 5 * 60 * 1000);
  },

  checkOutageStatus() {
    AdminService.getOutageStatus()
      .then(response => {
        this.setState({
          ...this.state,
          outageEnabled: response.enabled,
          outageMessage: response.message
        });
      })
      .catch(err => {
        console.error("Error checking outage status:", err);
      });
  },
});


const Bootstrap = window.Bootstrap = {
  init() {
    if (!("ontouchstart" in document.documentElement)) {
      document.documentElement.className += "no-touch";
    }
    // Getting configuration from server
    Service.getConfig()
    .then(c => {
      //@TODO- override serever config - for testing remove this later
      // c['clientId'] = '88a65839-f4d1-4953-9759-869271a95383';
      // c['tenant'] = 'a4996efc-c84c-4fc0-bfac-cc63cda7da45'
      // c['scKey'] = 'CAP-TEST';
      // c['scLoginUrl'] = 'https://beta.service.subzero.com';
      Config.set(c);
      return c;
    })
    .then(c => {
      if(c.apiServerUrl) {
        Api.setOption("apiUrl", c.apiServerUrl + c.apiUrl);
        AnonApi.setOption("apiUrl", c.apiServerUrl + c.apiUrl);
      }
    })
    .then(c => {
      AI.init(Config.applicationInsight);
      ReactDOM.render(<App />, document.getElementById("shell"))
    })
    .catch(err => {
      throw err;
    });
    // ReactDOM.render(<App />, document.getElementById("shell"));
  }
};



export default Bootstrap;