import React from "react";
import ReactClass from "create-react-class";
import AdminService from "./Service";
import Navbar from "./NavBar.jsx";

export default ReactClass({
  displayName: "OutageConfig",

  getInitialState() {
    return {
      message: "",
      outageEnabled: false,
      loading: true
    };
  },

  componentDidMount() {
    this.checkOutageStatus();
  },

  checkOutageStatus() {
    AdminService.getOutageStatus()
      .then(response => {
        this.setState({
          outageEnabled: response.enabled,
          message: response.message || "",
          loading: false
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  },

  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  },

  setOutage() {
    const { application } = this.props;
    this.setState({ loading: true });
    
    AdminService.setOutage(this.state.message)
      .then(() => {
        this.setState({
          message: this.state.message,
          loading: false,
          outageEnabled: true
        });
        application.notifications.success("Outage status set successfully");
        // Notify Main component through application context
        application.updateOutageStatus && application.updateOutageStatus();
      })
      .catch(err => {
        application.notifications.error("Failed to set outage status");
        this.setState({ loading: false });
      });
  },

  clearOutage() {
    const { application } = this.props;
    this.setState({ loading: true });
    
    AdminService.clearOutage()
      .then(() => {
        this.setState({ 
          message: "", 
          outageEnabled: false,
          loading: false 
        });
        application.notifications.success("Outage cleared successfully");
        // Notify Main component through application context
        application.updateOutageStatus && application.updateOutageStatus();
      })
      .catch(err => {
        application.notifications.error("Failed to clear outage");
        this.setState({ loading: false });
      });
  },

  render() {
    const { message, outageEnabled, loading } = this.state;

    return (
      <div className="view admin-config-view">
        <Navbar {...this.props}/>
        <div className="main-content">
          {loading ? (
            <div className="loading">
              <div className="_text-center anim">
                <i className="icon icon-loader spin"></i>
              </div>
              <p className="_text-center">Loading Outage Status...</p>
            </div>
          ) : (
            <div className="section">
              <div className="section-title">
                <h3>System Outage Management</h3>
              </div>
              <div className="outage-config">
                <div className="form-group">
                  <label>Outage Message:</label>
                  <textarea 
                    value={message}
                    onChange={this.handleMessageChange}
                    placeholder="Enter outage message to display to users..."
                    disabled={loading || outageEnabled}
                  />
                  {outageEnabled && (
                    <small className="help-text">Can not edit message when outage is set</small>
                  )}
                </div>
                <div className="section-control">
                  {!outageEnabled ? (
                    <button 
                      className="inline primary"
                      onClick={this.setOutage}
                      disabled={!message.trim() || loading}
                    >
                      <i className="icon icon-alert-triangle"></i>
                      Set Outage
                    </button>
                  ) : (
                    <button 
                      className="inline primary"
                      onClick={this.clearOutage}
                      disabled={loading}
                    >
                      <i className="icon icon-check-circle"></i>
                      Clear Outage
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}); 